import React, {useState} from 'react';
import {Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Notifications} from 'components/shared';
import {Statistics} from '../Statistics';
import {Stories} from '../Stories';
import {Requests} from '../Requests';
import {Achievements} from '../Achievements';

enum PROFILE_TAB {
    MY_STORIES = 'my-stories',
    NOTIFICATIONS = 'notifications',
    STATISTICS = 'statistics',
    REQUESTS = 'requests',
    ACHIEVEMENTS = 'achievements'
}

const TABS = [
    {
        title: 'Мои добрые дела',
        value: PROFILE_TAB.MY_STORIES,
        component: <Stories />
    },
    {
        title: 'Уведомления',
        value: PROFILE_TAB.NOTIFICATIONS,
        component: <Notifications />
    },
    {
        title: 'Статистика',
        value: PROFILE_TAB.STATISTICS,
        component: <Statistics />
    },
    {
        title: 'Обратная связь',
        value: PROFILE_TAB.REQUESTS,
        component: <Requests />
    },
    {
        title: 'Достижения',
        value: PROFILE_TAB.ACHIEVEMENTS,
        component: <Achievements />
    }
];
export const ProfileTabs = () => {
    const [tab, setTab] = useState(PROFILE_TAB.MY_STORIES);
    const handleChange = (value: PROFILE_TAB) => {
        setTab(value);
    };

    return (
        <TabContext value={tab}>
            <TabList
                variant='scrollable'
                onChange={(e, value) => handleChange(value)}
                sx={{py: 6, pb: {xs: 3, md: 6}, '& .MuiTabs-indicator': {display: 'block'}}}>
                {TABS.map((item, index) => (
                    <Tab
                        sx={{maxWidth: 'fit-content', px: 0, mr: 3, fontSize: {xs: 20, md: 24}}}
                        key={index}
                        label={item.title}
                        value={item.value}
                        disableRipple
                    />
                ))}
            </TabList>
            {TABS.map((item, index) => (
                <TabPanel key={index} value={item.value} sx={{p: 0}}>
                    {item.component}
                </TabPanel>
            ))}
        </TabContext>
    );
};

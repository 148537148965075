import {useAchievementControllerListForUser} from 'api/achievement';
import {CircularLoading} from 'components';
import {Avatar, Grid, Stack, Tooltip, Typography} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const Achievements = () => {
    const {data, isLoading} = useAchievementControllerListForUser();
    const achievements = data?.filter((i) => i.completeCoverFile && i.incompleteCoverFile && i.isActive);
    return (
        <CircularLoading isLoading={isLoading}>
            <Grid container rowGap={5}>
                {achievements?.map((achievement) => (
                    <Grid key={achievement.id} item xs={4}>
                        <Stack direction='column' alignItems='center' gap={2} position='relative'>
                            <img
                                src={achievement.isComplete ? achievement.completeCoverFile?.url : achievement.incompleteCoverFile?.url}
                                height={200}
                                width={200}
                            />
                            <Typography variant='h6' fontWeight={600} color={achievement.isComplete ? 'text.primary' : 'text.secondary'}>
                                {achievement.title}
                            </Typography>
                            <Tooltip title={achievement.description}>
                                <InfoOutlinedIcon fontSize='large' sx={{position: 'absolute', right: 40, top: 0}} />
                            </Tooltip>
                            {achievement.amount >= 2 && (
                                <Avatar sx={{position: 'absolute', left: 40, top: 0, bgcolor: 'primary.main'}}>
                                    <Typography>{achievement.amount}</Typography>
                                </Avatar>
                            )}
                        </Stack>
                    </Grid>
                ))}
            </Grid>
        </CircularLoading>
    );
};

/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {UserAchievementListDto} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const achievementControllerListForUser = (signal?: AbortSignal) => {
    return axiosInstance<UserAchievementListDto[]>({url: `/public/achievement`, method: 'get', signal});
};

export const getAchievementControllerListForUserQueryKey = () => [`/public/achievement`];

export type AchievementControllerListForUserQueryResult = NonNullable<Awaited<ReturnType<typeof achievementControllerListForUser>>>;
export type AchievementControllerListForUserQueryError = unknown;

export const useAchievementControllerListForUser = <
    TData = Awaited<ReturnType<typeof achievementControllerListForUser>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof achievementControllerListForUser>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAchievementControllerListForUserQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof achievementControllerListForUser>>> = ({signal}) =>
        achievementControllerListForUser(signal);

    const query = useQuery<Awaited<ReturnType<typeof achievementControllerListForUser>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
